<template>
  <div>
    <c-card title="작업위치(지도)" class="cardClassDetailForm" :noMarginPadding="true">
      <template slot="card-button">
        <q-btn-group outline>
          <c-btn v-if="editable&&isWriting" label="추가" icon="add" @btnClicked="addArea"/>
          <c-btn v-if="editable&&isWriting" label="지도" icon="place" @btnClicked="selectMap" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 container">
          <q-img
            :src="mapSrc"
            spinner-color="white"
            :contain="true"
            :style="{height:'575px', width: String(ratio*575) + 'px'}"
          >
            <template v-if="workPermit.maps && workPermit.maps.length > 0">
              <VueDraggableResizable
                v-for="(item, idx) in workPermit.maps"
                :key="idx"
                ref="markImage"
                class="markImage"
                :resizable="false"
                :parent="true"
                :draggable="editable&&isWriting"
                :x="item.locationXcoordinate"
                :y="item.locationYcoordinate"
                :w="40" :h="40"
                :grid="[20, 20]"
                @dragging="(x, y) => onDrag(x, y, item)"
              >
                <!-- @activated="clickMark(item)" -->
                {{idx + 1}}
              </VueDraggableResizable>
            </template>
          </q-img>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 container">
          <template v-if="workPermit.maps && workPermit.maps.length > 0">
            <div class="row"
              v-for="(item, idx) in workPermit.maps"
              :key="idx">
              <div class="col-10">
                <c-multi-select
                  :ref="'mark-work-multi-select-' + item.id"
                  class="q-pt-md"
                  :disabled="!isWriting"
                  :editable="editable"
                  :comboItems="supplyItems"
                  :isObject="true"
                  valueText="sopWorkTypeName"
                  valueKey="sopWorkTypeCd"
                  itemText="codeName"
                  itemValue="code"
                  :label="`보충작업 - ` + (idx + 1)"
                  name="resultTypes"
                  v-model="item.resultTypes"
                  @datachange="datachange">
                </c-multi-select>
              </div>
              <div class="col-2" style="position:relative;top:20px;">
                <c-btn v-if="editable&&isWriting" label="제외" icon="remove" @btnClicked="removePoint(idx)"/>
              </div>
            </div>
          </template>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swp-resources',
  components: { VueDraggableResizable },
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
        }
      }
    },
    supWorks: {
      type: Array,
      default: function() {
        return [];
      },
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      supplyItems: [],
      supplyOriginItems: [],
      sopMapId: '',
      ratio: 1,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
    sopMapId() {
      this.$comm.previewImageParamTask(
        this.sopMapId,
        'WORK_MAP'
      ).then(_result => {
        this.mapSrc = _result;
      });
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.map.recent.url
      // code setting
      this.$comm.getComboItems('SOP_SUPPLEMENT_WORK_TYPE_CD').then(_result => {
        this.supplyOriginItems = _result
        this.$comm.getComboItems('SOP_PERMIT_TYPE_CD').then(__result => {
          this.supplyOriginItems = this.$_.concat(this.supplyOriginItems, __result)
          this.setItems();
        });
      });
      // list setting
    },
    setItems() {
      this.supplyItems = [];
      if (this.workPermit.permitTypeCd === 'SPT0000001') {
        // 일반 허가서
        this.supplyItems = [
          this.$_.find(this.supplyOriginItems, { code: 'SPT0000001' })
        ]
      } else {
        // 화기 허가서
        this.supplyItems = [
          this.$_.find(this.supplyOriginItems, { code: 'SPT0000005' })
        ]
      }

      if (this.supWorks && this.supWorks.length > 0) {
        this.$_.forEach(this.supplyOriginItems, item => {
          if (this.$_.indexOf(this.supWorks, item.code) > -1 && this.$_.findIndex(this.supplyItems, { code: item.code }) === -1) {
            this.supplyItems.push(item)
          }
        })
      }
      this.datachange();

      if (this.workPermit.maps && this.workPermit.maps.length > 0) {
        this.ratio = this.$_.clone(this.workPermit.maps[0].ratio)
        this.sopMapId = this.$_.clone(this.workPermit.maps[0].sopMapId);
      } else {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          useFlag: 'Y',
          plantCd: this.workPermit.plantCd
        };
        this.$http.request((_result) => {
          if (_result.data) {
            // 이후에 지도 src 정보도 가져와서 뿌릴 수 있도록 처리
            this.sopMapId = _result.data.sopMapId;
            this.ratio = _result.data.ratio;
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내', 
              message: '지도정보를 등록 후 이용하시기 바랍니다.', 
              type: 'warning', // success / info / warning / error
            });
          }
        },);
      }
    },
    addArea() {
      this.workPermit.maps.push({
        sopMapResultId: uid(),
        sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
        sopMapId: this.sopMapId,  // 지도 일련번호
        locationXcoordinate: 0,  // 작업위치 X좌표
        locationYcoordinate: 0,  // 작업위치 Y좌표
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
        resultTypes: [],
      })
    },
    onDrag: function (x, y, item) {
      item.locationXcoordinate = x
      item.locationYcoordinate = y

      if (item.editFlag !== 'C') {
        item.editFlag = 'U'
        item.chgUserId = this.$store.getters.user.userId
      }
    },
    datachange() {
      let tempArr = this.$_.cloneDeep(this.supplyItems);
      this.$_.forEach(tempArr, item => {
        item.disable = false;
      });
      if (this.workPermit.maps && this.workPermit.maps.length > 0) {
        this.$_.forEach(this.workPermit.maps, point => {
          if (point.resultTypes && point.resultTypes.length > 0) {
            this.$_.forEach(tempArr, item => {
              if (this.$_.findIndex(point.resultTypes, { sopWorkTypeCd: item.code}) > -1) {
                item.disable = true;
              }
            });
          }
        })
      }
      this.supplyItems = tempArr
    },
    removePoint(idx) {
      let multiData = this.workPermit.maps[idx].resultTypes
      if (multiData && multiData.length > 0) {
        let tempArr = this.$_.cloneDeep(this.supplyItems);
        this.$_.forEach(tempArr, item => {
          if (this.$_.findIndex(multiData, { sopWorkTypeCd: item.code }) > -1) {
            item.disable = false;
          }
        })
        this.supplyItems = tempArr
      }
      if (!this.workPermit.deleteMaps) {
        this.workPermit.deleteMaps = [];
      }
      if (this.$_.findIndex(this.workPermit.deleteMaps, { sopMapResultId: this.workPermit.maps[idx].sopMapResultId }) === -1
        && this.workPermit.maps[idx].editFlag !== 'C') {
        this.workPermit.deleteMaps.push(this.workPermit.maps[idx])
      }

      this.workPermit.maps.splice(idx, 1)
    },
    selectMap() {
      this.popupOptions.title = '지도 검색'; 
      this.popupOptions.param = {
        plantCd: this.workPermit.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/base/workMapPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.ratio = data[0].ratio
        this.$comm.previewImage({
          sysAttachFileId: data[0].sysAttachFileId,
          contentType: data[0].contentType,
        }).then(_result => {
          this.mapSrc = _result;
        });

        this.sopMapId = data[0].sopMapId;
        if (this.workPermit.maps && this.workPermit.maps.length > 0) {
          this.$_.forEach(this.workPermit.maps, map => {
            map.sopMapId = data[0].sopMapId;
            if (map.editFlag !== 'C') {
              map.editFlag = 'U'
              map.chgUserId = this.$store.getters.user.userId
            }
          })
        }
      }
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important

</style>